import { Component, Vue, Prop } from "vue-property-decorator";
import { TeamMember } from "@/types/team";
import { StepAlias } from "@/types/productTour";
import debounce from "lodash.debounce";

const components = {
  TeamMemberActions: () =>
    import(
      "../../../../common/components/TeamMemberActions/TeamMemberActions.vue"
    ),
};

@Component({
  inheritAttrs: false,
  components,
})
export default class TeamMemberActionsMenu extends Vue {
  @Prop({ type: Object, required: true })
  private readonly teamMember!: TeamMember;

  private showedMenu = false;

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  private mounted() {
    components.TeamMemberActions();

    this.$watch(
      () => {
        return (
          this.$productTour.activeIntro.step.alias ===
          StepAlias.MOCK_TEAM_ACTIONS
        );
      },
      debounce((showedMenu) => {
        this.showedMenu = showedMenu;
      }, 50),
      {
        immediate: true,
      }
    );
  }
}
